import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useReportFlowrateConsumptionDaily } from 'apollo/filter-flowrates';
import { Filters } from './filters';
import { I18n } from 'i18n';
import ConsumptionGraph from '../../components/consumption-graph/consumption-graph';

const stateName = 'waterQuality.filterFlowrates.dailyConsumption';

export function GraphicDailyConsumption() {
  const dataDailyConsumption = useReportFlowrateConsumptionDaily();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);

  const {
    waterQuality: {
      filterFlowrates: {
        dailyConsumption: { dataPoint },
      },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if (dataPoint) {
      dataPoints.find((key) => {
        return key._id === dataPoint;
      });
    }
  }, [dataPoint, dataPoints]);

  return (
    <Container>
      <Filters stateName={stateName} />
      <ConsumptionGraph
        data={dataDailyConsumption.data}
        loading={dataDailyConsumption.loading}
        total={dataDailyConsumption.total}
        totalText={`${I18n('consumption', true)} ${I18n('total', true)} = ${
          dataDailyConsumption.total || 0
        } ${dataDailyConsumption.unit || 'm³'}`}
        title={`${I18n('daily_consumption', true)}`}
        leftLegend={`${I18n('consumption', true)} ${dataDailyConsumption.unit || 'm³'}`}
        xAccessor={`weekday`}
        bottomLegend={`${I18n('day', true)}`}
        tickValues={['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
      />
    </Container>
  );
}
